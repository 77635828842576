import { createAsyncThunk } from "@reduxjs/toolkit";
import { detailPDF } from "../../Api/detailPDF";

// Thunk action for getting a list of network locations

export const detailPDFData = createAsyncThunk(
  "detailPDFData",
  async (detailpdfdata, { rejectWithValue }) => {
    try {
      const response = await detailPDF(detailpdfdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
