import { Header } from "antd/es/layout/layout";
import "./Header.scss";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { getUserData } from "../../../services/Store/GetUserData/actions";

const Headers = () => {
  // const dispatch = useDispatch();
  

  useEffect(() => {
    // dispatch(getUserData());

  }, []);

  return (
    <Header className="shadow-md h-[12vh] w-full flex align-middle fixed z-10 myClass">
      <h1>Header</h1>
    </Header>
  );
};

export default Headers;
