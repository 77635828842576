import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadPDF } from "../../Api/uploadPDF";

// Thunk action for getting a list of network locations

export const uploadPDFData = createAsyncThunk(
  "uploadPDFData",
  async (uploadpdfdata, { rejectWithValue }) => {
    try {
      const response = await uploadPDF(uploadpdfdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
