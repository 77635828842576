import { createSlice } from "@reduxjs/toolkit";
import { detailPDFData } from "./actions";

const initialState = {
  detailList: [],
  detailLoading: false,
  detailError: null,
};
const detailPDFSlice = createSlice({
  name: "detailPDF",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
      .addCase(detailPDFData.pending, (state) => {
        state.detailLoading = true;
        state.detailError = null;
      })
      .addCase(detailPDFData.fulfilled, (state, action) => {
        state.detailLoading = false;
        console.log('action',action),
        state.detailList = action.payload;
      })
      .addCase(detailPDFData.rejected, (state, action) => {
        state.detailLoading = false;
        state.detailError = action.payload;
      });
  },
});
export default detailPDFSlice.reducer;
