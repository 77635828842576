import { createSlice } from "@reduxjs/toolkit";
import { queToPDFData } from "./actions";

const initialState = {
  queToList: [],
  queToLoading: false,
  queToError: null,
};
const queToPDFSlice = createSlice({
  name: "queToPDF",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(queToPDFData.pending, (state) => {
        state.queToLoading = true;
        state.queToError = null;
      })
      .addCase(queToPDFData.fulfilled, (state, action) => {
        state.queToLoading = false;
        console.log("action", action), (state.queToList = action.payload);
      })
      .addCase(queToPDFData.rejected, (state, action) => {
        state.queToLoading = false;
        state.queToError = action.payload;
      });
  },
});
export default queToPDFSlice.reducer;
