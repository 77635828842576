import { createAsyncThunk } from "@reduxjs/toolkit";
import { listPDF } from "../../Api/listPDF";

// Thunk action for getting a list of network locations

export const listPDFData = createAsyncThunk(
  "listPDFData",
  async (listPDFData, { rejectWithValue }) => {
    try {
      const response = await listPDF(listPDFData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
