import { Content } from "antd/es/layout/layout";
import React, { Suspense } from "react";
import Headers from "./Header";
import { Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";

const AuthLayout = (props) => {
  const { pathname } = useLocation();

  return (
    <Layout>
      <Headers />
      <div className="pt-16">
        <div className="p-4">
          <Content>
            <Suspense fallback="loading">
              <Outlet></Outlet>
            </Suspense>
          </Content>
        </div>
      </div>
    </Layout>
  );
};
export default AuthLayout;
