import { configureStore } from "@reduxjs/toolkit";
import uploadPDFReducer from "./UploadPDF/slice";
import listPDFReducer from "./ListPDF/slice";
import deletePDFReducer from './DeletePDF/slice'
import detailPDFReducer from './DetailPDF/slice'
import queToPDFReducer from './QueToPDF/slice'

const store = configureStore({
  reducer: {
    uploadPDF: uploadPDFReducer,
    listPDF: listPDFReducer,
    deletePDF: deletePDFReducer,
    detailPDF: detailPDFReducer,
    queToPDF: queToPDFReducer,
    // Add other reducers here if needed
  },
});

export default store;
