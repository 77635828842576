import { createSlice } from "@reduxjs/toolkit";
import { uploadPDFData } from "./actions";

const initialState = {
  uploadList: [],
  uploadLoading: false,
  uploadError: null,
};
const uploadPDFSlice = createSlice({
  name: "uploadPDF",
  initialState,
  reducers: {
    setUploadList: (state, action) => {
      state.uploadList = action?.payload;
    },
  },
  extraReducers: (builder) => {
      builder
      .addCase(uploadPDFData.pending, (state) => {
        state.uploadLoading = true;
        state.uploadError = null;
      })
      .addCase(uploadPDFData.fulfilled, (state, action) => {
        state.uploadLoading = false;
        console.log('action',action),
        state.uploadList = action.payload;
      })
      .addCase(uploadPDFData.rejected, (state, action) => {
        state.uploadLoading = false;
        state.uploadError = action.payload;
      });
  },
});
export default uploadPDFSlice.reducer;

export const { 
  setUploadList
} = uploadPDFSlice.actions
