import { createSlice } from "@reduxjs/toolkit";
import { listPDFData } from "./actions";

const initialState = {
  pdfList: [],
  pdfListLoading: false,
  pdfListError: null,
};
const listPDFSlice = createSlice({
  name: "listPDF",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
      .addCase(listPDFData.pending, (state) => {
        state.pdfListLoading = true;
        state.pdfListError = null;
      })
      .addCase(listPDFData.fulfilled, (state, action) => {
        state.pdfListLoading = false;
        console.log('action',action),
        state.pdfList = action.payload;
      })
      .addCase(listPDFData.rejected, (state, action) => {
        state.pdfListLoading = false;
        state.pdfListError = action.payload;
      });
  },
});
export default listPDFSlice.reducer;
