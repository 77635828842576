import { createAsyncThunk } from "@reduxjs/toolkit";
import { queToPDF } from "../../Api/queToPDF";

// Thunk action for getting a list of network locations

export const queToPDFData = createAsyncThunk(
  "queToPDFData",
  async (queTopdfdata, { rejectWithValue }) => {
    try {
      const response = await queToPDF(queTopdfdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
