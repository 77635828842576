import { createAsyncThunk } from "@reduxjs/toolkit";
import { deletePDF } from "../../Api/deletePDF";

// Thunk action for getting a list of network locations

export const deletePDFData = createAsyncThunk(
  "deletePDFData",
  async (deletepdfdata, { rejectWithValue }) => {
    try {
      const response = await deletePDF(deletepdfdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
