import { createSlice } from "@reduxjs/toolkit";
import { deletePDFData } from "./actions";

const initialState = {
  deleteList: [],
  deleteLoading: false,
  deleteError: null,
};
const deletePDFSlice = createSlice({
  name: "deletePDF",
  initialState,
  reducers: {
    setDeleteList: (state, action) => {
      state.deleteList = action?.payload;
    },
  },
  extraReducers: (builder) => {
      builder
      .addCase(deletePDFData.pending, (state) => {
        state.deleteLoading = true;
        state.deleteError = null;
      })
      .addCase(deletePDFData.fulfilled, (state, action) => {
        state.deleteLoading = false;
        console.log('action',action),
        state.deleteList = action.payload;
      })
      .addCase(deletePDFData.rejected, (state, action) => {
        state.deleteLoading = false;
        state.deleteError = action.payload;
      });
  },
});
export default deletePDFSlice.reducer;

export const { 
  setDeleteList
} = deletePDFSlice.actions