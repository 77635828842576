import { get, post, patch, del } from "../../helpers/apiWrapper";

export const queToPDF = async (data) => {
  // data: for request body
  // params: for request parameters
  const config = {
    data: data,
  };

  return await post(`/api/qa_with_pdf`, config);
};
