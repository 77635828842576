export default {
  // theme: {
  token: {
    // colorPrimary: "#09B5A5",
    borderRadius: "6px",
    // colorBgContainer:'red'
    // colorTextBase: "#d89522",
    // colorTextHeading: "#d89522",
    // colorTextLabel: "#d89522",
    // colorTextDescription: "#d89522",
  },
  // },
};
